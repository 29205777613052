import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { styled } from "@mui/material/styles";

import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js";
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js";
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js";

import LandingPage from 'LandingPageMaterial/Layout/LandingPage'
import SectionTemplate from 'LandingPageMaterial/Views/Sections/SectionTemplate'
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionNoticiasStyle";
import NewsCard from 'components/Core/Card/NewsCard';

const NewDiv = styled("div")(({ theme }) => {
  return { 
      ...sectionStyle.container
   }
});

const NewH1 = styled("h1")(({ theme }) => {
  return { 
      ...sectionStyle.title
   }
});

export default function Noticias() {
  
  
  const data = useStaticQuery(graphql`
    query {
      allStrapiNoticia(
        sort: {order: DESC, fields: fecha_publicacion}
        filter: {categoria_publicacion: {identificador_categoria: {eq: "2"}}}
      ) {
        edges {
          node {
            id
            autor
            titulo_noticia
            cuerpo_noticia {
              data {
                cuerpo_noticia
              }
            }
            categoria_publicacion {
              identificador_categoria
            }
            area_seguro_noticia {
              nombre_area_seguro
            }
            imagen_principal {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                }
              }
            }
            imagen_alterna {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 700, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
      allStrapiAssetsMedia(filter: {codigo: {eq: "NOTICIAS_BACKGROUND"}}) {
        edges {
          node {
            archivo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const noticias = data.allStrapiNoticia.edges;
  const backgroundImage = data.allStrapiAssetsMedia.edges[0].node.archivo.localFile.childImageSharp.gatsbyImageData.images.fallback.src
  
  return (
    <LandingPage noLinks>
      <Parallax small image={backgroundImage}>
          <NewDiv>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} className={sectionStyle.textCenter}>
                    <NewH1></NewH1>
                </GridItem>
              </GridContainer>
          </NewDiv>
      </Parallax>
      <SectionTemplate>
      <GridContainer>
        {noticias.map( ({node},index) =>(
          <NewsCard
            node={node}
            index={index}
            key={`${index}_a`}
            mdSize={4}
          />
        ))}
      </GridContainer>
      </SectionTemplate>
    </LandingPage>
  )
}